import React, { useState, useEffect } from 'react';
import './App.css';

const appsData = [
  { 
    name: "My Social Manager", 
    image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/my_social_manager/mysm.png", 
    description: `A powerful tool to manage your social media accounts. 
    Visit the website <a href="https://mysocialmanager.app/" target="_blank" rel="noopener noreferrer">here</a>. 
    Access the backend <a href="https://portal.mysocialmanager.app/" target="_blank" rel="noopener noreferrer">here</a>.`
  },
  { name: "VK Analytics", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_analytics/VK%20ANALYTICS.png", description: "Analyze data with VK Analytics." },
  { name: "VK Payments", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_pay/VKPAY3.png", description: "A seamless payment platform." },
  { name: "VK Image Generator", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_image/vkImage3.png", description: "Generate images using AI." },
  { name: "VK Edu", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_edu/vkEdu1.png", description: "Education management platform." },
  { name: "VK Audio Processor", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_audio/vkAudio2.png", description: "Process and enhance audio." },
  { name: "VK Accent Changer", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_accent/vkAccent.png", description: "Change and improve accents." },
  { name: "Kazi Link", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/kazi_link/kazilink.png", description: "A job-matching platform." },
  { name: "VK Leads", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vk_leads/vkLeads2.png", description: "Manage and track your leads." },
  { name: "SGA Crypto", image: "https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/sga_crypto/sga1.png", description: "Cryptocurrency tracking platform." }
];

// Static URLs only. Metadata will be dynamically fetched.
const websitesData = [
  { name: "Vision Kraft Consulting", url: "https://visionkraftconsulting.com" },
  { name: "My Social Manager", url: "https://mysocialmanager.app" },
  { name: "Hearts United Homecare", url: "https://heartsunitedcare.com/" },
  { name: "Caring Hearts Medical Staffing", url: "https://chmstaffing.com/" },
  { name: "Serenity Safaris", url: "https://serenityhomessafaris.com/" },
  { name: "SGA Crypto", url: "https://sgacrypto.app/" },
];

function App() {
  const [expandedApp, setExpandedApp] = useState(null);
  const [currentTab, setCurrentTab] = useState('Apps'); // Default to 'Apps' tab
  const [websitesInfo, setWebsitesInfo] = useState([]); // State to hold dynamically fetched website data
  const [loading, setLoading] = useState(false); // Loading state for websites

  // Fetch metadata and screenshots from the backend only when Websites tab is selected for the first time
  useEffect(() => {
    if (currentTab === 'Websites' && websitesInfo.length === 0) {
      fetchWebsiteData();
    }
  }, [currentTab]);

  const toggleExpand = (appName) => {
    setExpandedApp(expandedApp === appName ? null : appName);
  };

  // Fetch metadata and screenshots for each website from the backend
  const fetchWebsiteData = async () => {
    setLoading(true); // Start loading
    try {
      const fetchedWebsites = await Promise.all(
        websitesData.map(async (website) => {
          const response = await fetch(`http://localhost:3013/scrape?url=${website.url}`);
          // const response = await fetch(`http://localhost:3013/scrape?url=${website.url}`);
          const data = await response.json();
          return { ...website, ...data }; // Combine website info with scraped metadata
        })
      );
      setWebsitesInfo(fetchedWebsites);
    } catch (error) {
      console.error('Error fetching website data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const renderApps = () => (
    <div className="App-showcase">
      {appsData.map((app) => (
        <div key={app.name} className="App-item">
          <img
            src={app.image}
            alt={app.name}
            className="App-item-image"
            onClick={() => toggleExpand(app.name)}
          />
          <h3 onClick={() => toggleExpand(app.name)}>{app.name}</h3>
          {expandedApp === app.name && (
            <div className="App-details">
              <p>{app.description}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
  

  const renderWebsites = () => {
    if (loading) {
      return <div className="loading-spinner">Loading...</div>; // Show loading animation
    }
    return (
      <div className="Websites-showcase">
        {websitesInfo.map((website) => (
          <div key={website.name} className="Website-item">
            <h3>
              <a href={website.url} target="_blank" rel="noopener noreferrer">
                {website.title || website.name}
              </a>
            </h3>
            <p>{website.description || "No description available"}</p>
            {website.screenshot && (
              <img src={website.screenshot} alt={`${website.name} screenshot`} />
            )}
            {website.seoSuggestions && (
              <div className="seo-suggestions">
                <h4>SEO Suggestions:</h4>
                <ul>
                  {website.seoSuggestions.map((suggestion, index) => (
                    <li key={index}>{suggestion}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://visionkraft-bucket.s3.us-east-1.amazonaws.com/logos/vision_kraft/logo_1.png" className="App-logo" alt="logo" />
        <h3>Welcome to Vision Kraft</h3>
        <p>Full-Stack Developer Showcase</p>
      </header>

      {/* Tabs */}
      <div className="App-tabs">
        <button className={currentTab === 'Apps' ? 'active-tab' : ''} onClick={() => setCurrentTab('Apps')}>Apps</button>
        <button className={currentTab === 'Websites' ? 'active-tab' : ''} onClick={() => setCurrentTab('Websites')}>Websites</button>
      </div>

      {/* Render content based on the selected tab */}
      {currentTab === 'Apps' ? renderApps() : renderWebsites()}
    </div>
  );
}

export default App;
